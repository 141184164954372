import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import VideoPlayer from "../popups/VideoPlayer";
import EZAlert from "../popups/EZAlert";

export default class VideoThumb extends ComponentBase
{
  constructor(elem: ComponentElem)
  {
    super(elem);
    
    let videoId = elem.dataset.videoId,
      isShort = elem.dataset.isShort === "false"? false: true;
    
    this.$root.on("click", (event:JQuery.ClickEvent)=>
      {
        event.preventDefault();

        if (videoId && videoId !== "")
        {
          VideoPlayer.instance.playVideo(videoId, isShort);
        }
        else
        {
          EZAlert.alert("影片籌備中，敬請期待");
        }
        
      }); 
  }
}