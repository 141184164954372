import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import Utility from "../../ts/sframe/Utility";
import gsap from "gsap";
import Tools from "../../ts/Tools";

export default class Intro extends ComponentBase
{
  constructor(elem: ComponentElem)
  {
    super(elem);
    
    this._setupAnimate();
  }

  

  private _setupAnimate()
  {
    if (Utility.urlParams.test === "layout") return;

    let q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root[0],
      start: "top center",
      end: "bottom 100%",
      // markers: true,
    };

    
    if (Utility.urlParams.test === "animation") trigger = undefined;

    let tl, mainTl;

    tl =  gsap.timeline();

    tl.add(()=>
    {
      this.stopSparks();
    })

    tl.set(q(".ao"), {opacity: 0, scaleY: .3, marginTop: 100, transformOrigin: "center bottom"});
    tl.set(q(".title"), {opacity: 0, transformOrigin: "center bottom"});

    tl.addLabel("start", .01);

    tl.to(q(".title"), {duration: .7, opacity: 1, ease: "power1.in"}, "start");
    tl.to(q(".ao"), {duration: .4, opacity: 1, scaleY: 1, marginTop: 0, stagger: .05, ease: "back.out"}, "start+=.3");

    tl.add(()=>
    {
      this.playSparks();
    });


    // 混合動畫
    mainTl = gsap.timeline({ scrollTrigger: trigger });
    // mainTl.set(q(".graphic-part"), { opacity: 0 });
    mainTl.addLabel("start", .01);
    mainTl.add(tl, 0);
    // mainTl.addLabel("tl2-in", "-=0.5");

    // this._mainTl = mainTl;

    mainTl.add(() =>
    {
      // console.log("end");
    }, "+=.5");

    mainTl.seek("start").pause();

    // 測試用
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, mainTl, "start");
  }

  playSparks()
  {
    let $sparks = this.$root.find(`[component="intro-spark"]`);
    $.each($sparks, (index, elem: ComponentElem)=>
    {
      elem._component.play();
    });
    
  }

  stopSparks()
  {
    let $sparks = this.$root.find(`[component="intro-spark"]`);
    $.each($sparks, (index, elem: ComponentElem)=>
    {
      elem._component.stop();
    });
  }
}