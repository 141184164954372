import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import EZAlert from "../popups/EZAlert";
import VideoPlayer from "../popups/VideoPlayer";

const BLUR_SIZE = 40;

export default class ModSvg extends ComponentBase
{
  constructor(elem: ComponentElem)
  {
    super(elem);

    // return;

    let img = this.$root.find("img")[0];

    window.SVGInject(img, { makeIdsUnique: true }).then(() =>
    {
      if (this.$root.attr("type") === "site")
      {

        this._build();
      }
    });
  }

  private _build()
  {
    let videoId = this.$root[0].dataset.videoId,
      isShort = this.$root[0].dataset.isShort === "false"? false: true;

    let $svg = this.$root.find("svg");

    $svg[0].innerHTML = `<g class="svg-content">` + $svg[0].innerHTML + `</g>`;

    if(!videoId || videoId === "")
    {


      $svg.find(".svg-content").on("click", (event: JQuery.ClickEvent) =>
        {
          event.preventDefault();
          
          
          EZAlert.alert("影片籌備中，敬請期待");
        });

      return;
    }

    let width = parseFloat($svg.attr("width")),
      height = parseFloat($svg.attr("height")),
      w = width + BLUR_SIZE * 2,
      h = height + BLUR_SIZE * 2,
      b = -BLUR_SIZE;

    $svg.attr("width", w + "px");
    $svg.attr("height", h + "px");

    $svg.attr("viewBox", `${b} ${b} ${w} ${h}`);

    this.$root.attr("modified", "true").css({
      "width": width + "px",
      "height": height + "px"
    });

    $svg.find(".svg-content").on("click", (event: JQuery.ClickEvent) =>
    {
      event.preventDefault();
      

      VideoPlayer.instance.playVideo(videoId, isShort);
    });



  }
}