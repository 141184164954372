import Main from "@root/Main";
import { ComponentDefineDic, ComponentElem } from "@components/interfaces";
import Components from "@components/Compoents";
import Utility from "../../ts/sframe/Utility";
import Popups from "../popups/Popups";
import ModSvg from "./ModSvg";
import TaiwanMaps from "./TaiwanMaps";
import Map from "./Map";
import VideoSection from "./VideoSection";
import VideoList from "./VideoList";
import { ScrollListener } from "../../ts/sframe/ScrollListener";
import PageRoot from "../common/PageRoot";
import Tools from "../../ts/Tools";
import { Emitter } from "strict-event-emitter";
import Intro from "./Intro";
import IntroSpark from "./IntroSpark";
import Generator from "./Generator";
import Info from "./Info";
import VideoThumb from "./VideoThumb";

let _componentDic: ComponentDefineDic =
{
  "mod-svg": ModSvg,
  "map": Map,
  "taiwan-maps": TaiwanMaps,
  "video": VideoSection,
  "video-list": VideoList,
  "video-thumb": VideoThumb,
  "intro": Intro,
  "intro-spark": IntroSpark,
  "generator": Generator,
  "info": Info,
}

let $root;

Main.preInitPage = () =>
{
  // ApiProxy.validatePageData("page-data", page_data_schema);
  // ApiProxy.logPageData();
}

Main.initPage = () =>
{
  $root = $("#page-content");

  Tools.sectionScrollMonitor(true);


  let $sections = $root.find("section"),
    goodHashDic: any = {};

  $.each($sections, (index, elem)=>
  {
    goodHashDic[elem.id] = true;
  });

  let name = Tools.getHash();
  if(goodHashDic[name] !== undefined)
  {
    Tools.scrollToElement(name);
  }



  

}

Components.registComponents(_componentDic);

