import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import VideoList from "./VideoList";
import EZAlert from "../popups/EZAlert";
import VideoPlayer from "../popups/VideoPlayer";
import Utility from "../../ts/sframe/Utility";
import gsap from "gsap";
import Tools from "../../ts/Tools";

export default class VideoSection extends ComponentBase
{
  private _$stars: JQuery;

  constructor(elem: ComponentElem)
  {
    super(elem);

    let videoList: VideoList = this.seekComponentByName("video-list");

    let $stars = this._$stars = this.$root.find(".stars").find(".star");

    let numGroups = this.$root.find(".group").length;

    $.each($stars, (index, elem) =>
    {
      let $elem = $(elem);

      if (index >= numGroups)
      {
        $elem.css("display", "none");
      }

      $elem.on("click", (event: JQuery.ClickEvent) =>
      {
        event.preventDefault();

        // console.log(index);
        videoList.toIndex(index);
      });

      this._setupAnimate();
    });


    videoList.emitter.on("updated", (index: number) =>
    {
      // console.log(`new page index: ${index}`);

      this._setStarIndex(index);
    });

    this._setupVideoButtons();
  }

  private _setupVideoButtons()
  {
    let $clips = this.$root.find(".clip");

    $clips.on("click", function (event: JQuery.ClickEvent)
    {
      event.preventDefault();

      let videoId = this.dataset.videoId;
      if (videoId && videoId !== "")
      {
        // console.log(this.dataset.isShort === "true");
        let isShort = false;
        if (this.dataset.isShort === "true") isShort = true;
        VideoPlayer.instance.playVideo(videoId, isShort);
      }
      else
      {
        EZAlert.alert("影片籌備中，敬請期待");
      }

    });
  }

  private _setStarIndex(index: number)
  {
    this._$stars.attr("focused", "false");
    this._$stars[index].setAttribute("focused", "true");
  }



  private _setupAnimate()
  {
    if (Utility.urlParams.test === "layout") return;

    let q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root[0],
      start: "0% 50%",
      end: "100% 50%",
      // markers: true,
    };


    if (Utility.urlParams.test === "animation") trigger = undefined;

    let tl, mainTl;

    tl = gsap.timeline();

    tl.set(q(".ao"), { opacity: 0, scaleY: .3, marginTop: 100, transformOrigin: "center bottom" });

    tl.addLabel("start", .01);

    tl.to(q(".ao"), { duration: .4, opacity: 1, scaleY: 1, marginTop: 0, stagger: .05, ease: "back.out", clearProps: "all" }, "start+=.1");

    tl.add(() =>
    {
      // this._twMaps.to("1");

    }, "+=.1");


    // 混合動畫
    mainTl = gsap.timeline({ scrollTrigger: trigger });
    mainTl.addLabel("start", .01);
    mainTl.add(tl, 0);

    mainTl.add(() =>
    {
      // console.log("end");
    }, "+=.5");

    mainTl.seek("start").pause();

    // 測試用
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, mainTl, "start");
  }
}