import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import Utility from "../../ts/sframe/Utility";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/all";
import Tools from "../../ts/Tools";

export default class TaiwanMaps extends ComponentBase
{
  private _mapDic: { [key: string]: HTMLElement } = {};

  private _currentIndex: string;
  private _isLocking: boolean = false;

  getMapElem(index: string)
  {
    return this._mapDic[index];
  }

  constructor(elem: ComponentElem)
  {
    super(elem);

    if (Utility.urlParams.map)
    {
      this.$root.attr("index", Utility.urlParams.map);
    }


    let $maps = this.$root.find(">.content>.map");
    $maps.each((index, elem) =>
    {
      this._mapDic[elem.getAttribute("index")] = elem;
      elem.parentNode.removeChild(elem);
    });

    this._currentIndex = this.$root.attr("index");
    this.$root.find(".content").append(this._mapDic[this._currentIndex]);
    this.to(this._currentIndex);
  }

  to(index: string)
  {
    if (index === this._currentIndex) return;
    if (this._isLocking) return;


    // this.$root[0].setAttribute(`index`, index);

    // this.$root.find(".map").detach();

    // this.$root.find(".content").append(this._mapDic[index]);

    // old out

    this._isLocking = true;

    if (this._currentIndex)
    {
      oldOut.call(this);
    }
    else
    {
      newIn.call(this);
    }

    const SITE_ORDER: any = 
    {
      "1":[
        8,7,6,5,4,3,2,1,14,13,15,12,11,10,9
      ],
      "2":[
        5,4,3,2,1,12,11,13,10,9,8,7,6
      ],
      "3":[
        4,3,2,1,10,9,8,7,6,5
      ]
    };

    const MOTION_PATH = {
      path: [
        { x: 300, y: 100 },
        { x: -300, y: 0 },
        { x: -300, y: -400 },
        { x: 0, y: -400 },
        { x: 0, y: 0 }
      ]
    };

    function getSiteOrder(index: string, qurey: any) {
      let order = SITE_ORDER[index],
        result: any[] = [];

      order.forEach((siteIndex: number) => {
        result.push(qurey(`.site[index="${siteIndex}"] svg`)[0]);
      });

      return result;
    }
    

    function oldOut()
    {
      let currentMap = this._mapDic[this._currentIndex],
        q = gsap.utils.selector(currentMap),
        b = gsap.utils.selector(this.$root.find(".base")[0]),
        siteArray = q(".site");
      // siteArray = Tools.shuffle(q(".site"));

      $(currentMap).find(".site").css("animation", "none");

      let tl = gsap.timeline();
      tl.addLabel("koalaOut", .0);
      tl.addLabel("siteOut", .1);

      // tl.to(q(".koala"), { duration: .05 }, "koalaOut");
      // tl.to(q(".koala"), { duration: .3, opacity: 0, ease: "power3.out" }, "koalaOut+=.05");
      tl.to(q(".koala"), { duration: .7, filter: "blur(30px)", opacity: 0, ease: "power1.inOut" }, "koalaOut+=.05");



      tl.to(siteArray, { duration: .05, stagger: .05 }, "siteOut");
      tl.to(siteArray, { duration: .3, y: -100, opacity: 0, ease: "power3.out", stagger: .05 }, "siteOut+=.05");

      tl.addLabel("glassIn", "-=.5");

      tl.to(b(".glass"), { duration: .3, x: 300, ease: "power1.in" }, "glassIn");
      tl.to(b(".glass"), { duration: .3, y: -200, ease: "power1.out" }, "glassIn");

      tl.add(() =>
      {
        currentMap.parentNode.removeChild(currentMap);
        newIn.call(this);
      });
    }

    function newIn()
    {
      this._currentIndex = index;
      let currentMap = this._mapDic[this._currentIndex],
        q = gsap.utils.selector(currentMap),
        b = gsap.utils.selector(this.$root.find(".base")[0]);

      let orderedSites = getSiteOrder(index, q);

      // console.log(orderedSites);
      

      $(currentMap).find(".site").css("animation", "");

      gsap.set(currentMap, { opacity: 0 });
      this.$root.attr("index", this._currentIndex);
      this.$root.find(".content").append(currentMap);

      let tl = gsap.timeline();

      tl.set(b(".glass"), { x: 300, y: 100 });
      tl.set(q(".koala"), { filter: "blur(30px)", opacity: 0 });
      tl.set(q(".site"), { opacity: 1, y: 0 });
      tl.set(orderedSites, { scale: .0, y: 0, opacity: 1 });

      tl.addLabel("glassIn", "+=.1");
      tl.addLabel("koalaIn", "+=.3");
      tl.addLabel("siteIn", "+=.7");

      tl.add(() =>
      {
        gsap.set(currentMap, { opacity: 1 });
      }, "glassIn");

      // tl.to(b(".glass"), { duration: .3, x: 0, y: 0, ease: "power1.out" }, "glassIn");
      tl.to(b(".glass"), { duration: 1.3, motionPath: MOTION_PATH, ease: "linear" }, "glassIn");


      tl.to(q(".koala"), { duration: .7, opacity: 1, filter: "blur(0px)", ease: "power1.inOut" }, "koalaIn");
      tl.to(orderedSites, { duration: .5, scale: 1, opacity: 1, ease: "back.out", stagger: .05 }, "siteIn+=.2");


      tl.add(() =>
      {
        this._isLocking = false;
      })
    }

  }
}