import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import Utility from "../../ts/sframe/Utility";
import gsap from "gsap";
import Tools from "../../ts/Tools";

export default class Generator extends ComponentBase
{
  constructor(elem: ComponentElem)
  {
    super(elem);
    
    this._setupAnimate();
  }

  private _setupAnimate()
  {
    if (Utility.urlParams.test === "layout") return;

    let q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root[0],
      start: "0% 50%",
      end: "100% 50%",
      // markers: true,
    };

    
    if (Utility.urlParams.test === "animation") trigger = undefined;

    let tl, mainTl;

    tl =  gsap.timeline();
    
    this.toggleLoopAnimation(false);

    tl.add(()=>
    {
      this.toggleLoopAnimation(false);
    });

    tl.set(q(".ao"), {opacity: 0, scaleY: .3, marginTop: 100, transformOrigin: "center bottom"});
    tl.set(q(".co"), {opacity: "0!important"});
    tl.set(q(".title"), {opacity: 0, transformOrigin: "center bottom"});

    tl.addLabel("start", .01);

    // tl.to(q(".title"), {duration: .7, opacity: 1, ease: "power1.in"}, "start");
    tl.to(q(".ao"), {duration: .4, opacity: 1, scaleY: 1, marginTop: 0, stagger: .05, ease: "back.out"}, "start+=.3");
    
    tl.to(q(".co"), {duration: .4, opacity: 1}, "-=.1");

    tl.add(()=>
    {
      this.toggleLoopAnimation(true);
    });


    // 混合動畫
    mainTl = gsap.timeline({ scrollTrigger: trigger });
    mainTl.addLabel("start", .01);
    mainTl.add(tl, 0);

    mainTl.add(() =>
    {
      // console.log("end");
    }, "+=.5");

    mainTl.seek("start").pause();

    // 測試用
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, mainTl, "start");
  }

  toggleLoopAnimation(b: boolean)
  {
    this.$root.find(".content").find(".twinkle").attr("no-animation", b? "false": "true");
  }
}