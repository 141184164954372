import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import TaiwanMaps from "./TaiwanMaps";
import Utility from "../../ts/sframe/Utility";
import gsap from "gsap";
import Tools from "../../ts/Tools";

export default class Map extends ComponentBase
{
  private _twMaps: TaiwanMaps;

  constructor(elem: ComponentElem)
  {
    super(elem);

    let self = this;
    
    this._twMaps = this.seekComponentByName("taiwan-maps");

    let $btns = this.$root.find(`.buttons>.btn`);

    $btns.on("click", function(event:JQuery.ClickEvent)
    {
      event.preventDefault();
      
      let index:string = this.getAttribute("index");      
      // console.log(this);
      
      self._twMaps.to(index);
    });

    this._setupAnimate();
    
  }

  

  private _setupAnimate()
  {
    if (Utility.urlParams.test === "layout") return;

    let q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root[0],
      start: "0% 50%",
      end: "100% 50%",
      // markers: true,
    };

    
    if (Utility.urlParams.test === "animation") trigger = undefined;

    let $firstMapSites = $(this._twMaps.getMapElem("1")).find(".site");
    // console.log($firstMapSites);
    

    let tl, mainTl;

    tl =  gsap.timeline();

    tl.set(q(".ao"), {opacity: 0, scaleY: .3, marginTop: 100, transformOrigin: "center bottom"});
    tl.set($firstMapSites, {opacity: 0});

    tl.addLabel("start", .01);

    tl.to(q(".ao"), {duration: .4, opacity: 1, scaleY: 1, marginTop: 0, stagger: .05, ease: "back.out", clearProps: "all"}, "start");
    tl.to($firstMapSites, {duration: .6, opacity: 1, stagger: .03}, "start+=.3");

    tl.add(()=>
    {
      // this._twMaps.to("1");
      
    }, "+=.1");


    // 混合動畫
    mainTl = gsap.timeline({ scrollTrigger: trigger });
    mainTl.addLabel("start", .01);
    mainTl.add(tl, 0);

    mainTl.add(() =>
    {
      // console.log("end");
    }, "+=.5");

    mainTl.seek("start").pause();

    // 測試用
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, mainTl, "start");
  }
}