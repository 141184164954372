
import { ComponentElem } from "@components/interfaces";
import Scrollable from "@components/ui/Scrollable";

export default class VideoList extends Scrollable
{
  constructor(elem: ComponentElem)
  {
    super(elem, {
      itemWidth: 540,
      itemHeight: 343,
      gapX: 12,
      gapY: 12,
      numCols: 1,
      buttonMode: "click"
    });
  }
}