import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import gsap from "gsap";

const WIDTH = 160,
  HEIGHT = 160;

export default class IntroSpark extends ComponentBase
{
  private _isPlaying: boolean = false;

  private _isLocking: boolean = false;

  private _tl: any;

  constructor(elem: ComponentElem)
  {
    super(elem);

    gsap.set(this.$root, {opacity: 0});

    // this.play();
  }

  play()
  {
    if(this._isPlaying) return;
    this._isPlaying = true;

    this._playOnce();
  }

  stop()
  {
    this._isPlaying = false;
  }

  private _playOnce()
  {
    if(this._isLocking) return;
    this._isLocking = true;

    let targetScale = .6 + Math.random() * .4,
      delay = .1 + Math.random() * .4,
      duration = 3 + Math.random() * 2;

    let tl = gsap.timeline();
    tl.set(this.$root, {y: 50, opacity: 0, scale: targetScale});
    tl.to(this.$root, {duration: .05, opacity: 1});
    tl.to(this.$root, {duration: .5, y: -50, ease: "power1.out"}, 0);
    tl.addLabel("bust", "-=.1");
    tl.to(this.$root, {duration: duration, y: 100, ease: "power1.in"});
    tl.to(this.$root, {duration: 1, opacity: 0}, "-=1");

    tl.add(()=>{ this._toFrame(1) }, 0);
    tl.add(()=>{ this._toFrame(2) }, "bust+=.03");
    tl.add(()=>{ this._toFrame(3) }, "bust+=.06");
    tl.add(()=>{ this._toFrame(4) }, "bust+=.09");

    tl.add(()=>
    {
      this._isLocking = false;
      if(this._isPlaying) this._playOnce();
    }, "+=" + delay);
    
  }

  private _toFrame(index: number)
  {
    this.$root.css("background-position-x", -WIDTH* (index-1));
  }
}