import ComponentBase from "@components/ComponentBase";
import { ComponentElem, ScrollableSetting } from "@components/interfaces";
import ViewportRoot from "@ts/sframe/ViewportRoot";
import gsap from "gsap";
import { Emitter } from "strict-event-emitter";

const SPEED: number = 800;

/**
 * buttonMode 為 "press" 時按鈕按住不放捲動
 * buttonMode 為 "click" 時按鈕點一下捲一個物件
 */
export default class Scrollable extends ComponentBase
{
  $wrapper: JQuery;

  $items: JQuery;
  numItems: number;

  setting: { [key: string]: ScrollableSetting } = { "pc": null, "mobile": null };
  haveMutipleSetting: boolean;

  currentIndex: number = 0;

  minIndex: number = 0;
  maxIndex: number = 0;

  minX: number = 0;
  maxX: number = 0;

  targetX: number = 0;
  targetDirection: number = 0;

  hamPrev: HammerManager;
  hamNext: HammerManager;

  hamDrag: HammerManager;

  isDragging: boolean = false;

  haveButtons: boolean;

  view: "pc" | "mobile" = "pc";

  scrollable: boolean = false;
  isLocking: boolean = false;

  timer: any;
  pressMoveObj: any = {
    targetX: 0,
    speed: 0,
    direction: 1,
    skipTween: false
  };

  emitter:Emitter<{updated:[index: Number]}>;

  constructor(elem: ComponentElem, pcSetting: ScrollableSetting, mobileSetting?: ScrollableSetting)
  {
    super(elem);

    this.emitter = new Emitter();

    let self = this;

    if (pcSetting && !pcSetting.reboundDistance) pcSetting.reboundDistance = "auto";
    if (mobileSetting && !mobileSetting.reboundDistance) mobileSetting.reboundDistance = "auto";


    this.setting.pc = pcSetting;
    if (mobileSetting) this.setting.mobile = mobileSetting;

    this.$wrapper = this.$root.find(".wrapper");

    // this.$wrapper.css("transition", `margin-left 300ms ease-out`);

    this.$wrapper.find(">*").attr("draggable", "false");

    this.$items = this.$wrapper.find("> *");
    this.numItems = this.$items.length;
    

    let $btnPrev = this.$root.find(".btn-prev"),
      $btnNext = this.$root.find(".btn-next");


    $btnPrev.on("click", (event: Event) =>
    {
      event.preventDefault();
      
      if (this.getSetting().buttonMode !== "click") return;
      this.toIndex(this.currentIndex - 1);
    });

    $btnNext.on("click", (event: Event) =>
    {
      event.preventDefault();
      if (this.getSetting().buttonMode !== "click") return;
      this.toIndex(this.currentIndex + 1);
    });

    let $win = $(window);

    this.haveButtons = Boolean($btnPrev[0] && $btnNext[0]);

    if (this.haveButtons) {
      this.hamPrev = new Hammer($btnPrev[0]);
      this.hamNext = new Hammer($btnNext[0]);

      this.hamPrev.get("press").set({ time: 0 });
      this.hamNext.get("press").set({ time: 0 });

      this.hamPrev.on("press", () =>
      {
        if (this.getSetting().buttonMode !== "press") return;

        // console.log("prev start");
        this.updateForPress(1);
        $win.on("touchend mouseup", onMouseUp);

      });

      this.hamNext.on("press", () =>
      {
        if (this.getSetting().buttonMode !== "press") return;

        // console.log("next start");
        this.updateForPress(-1);
        $win.on("touchend mouseup", onMouseUp);
      });
    }

    function onMouseUp()
    {
      // console.log("ham end");
      $win.unbind("touchend mouseup", onMouseUp);
      self.endUpdateForPress();
    }

    // let $cover = $(`<div class="event-blocker"></div>`);

    this.$wrapper.find(">*").on("dragstart", ()=>
    {
      return false;
    });

    (function ()
    {
      self.isDragging = false;

      let startX: number,
        targetX: number,
        $wrapper = self.$wrapper;

      let ham = self.hamDrag = new Hammer($wrapper[0], {});

            

      ham.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL });

      ham.on("panstart", (event: any) =>
      {        
        self.isDragging = true;
        startX = parseInt($wrapper.css("margin-left"));
        gsap.killTweensOf($wrapper[0]);
        // gsap.to($wrapper[0], { marginLeft: 0, duration: .1 });

        // console.log(event.srcEvent);

        self.$wrapper.find(">*").css("pointer-events", "none");
      });

      ham.on("pan", (event: any) =>
      {
        if (!self.isDragging) return;

        if (Math.abs(event.deltaY) > 200) return;

        targetX = startX + event.deltaX

        self.targetX = targetX;

        // if(targetX < self.minX) targetX = self.minX;
        // if(targetX > self.maxX) targetX = self.maxX;

        gsap.killTweensOf($wrapper[0]);
        // gsap.set($wrapper[0], { marginLeft: targetX });
        gsap.to($wrapper[0], { marginLeft: targetX, duration: .5, ease: `power1.out` });

      });

      ham.on("panend", (event: any) =>
      {
        setTimeout(() =>
        {
          self.isDragging = false;
          self.$wrapper.find(">*").css("pointer-events", "");
        }, 300);

        self.preRebound();
      });

    }());

    this.haveMutipleSetting = Boolean(this.setting.pc) && Boolean(this.setting.mobile);


    ViewportRoot.emitter.addListener("updated", () =>
    {
      this.updateSetting();
    });

    // if(this.haveMutipleSetting)
    // {
    //   ViewportRoot.emitter.addListener("majorChanged", ()=>
    //   {
    //     this.updateSetting();
    //   });
    // }
    // else
    // {
    //   this.updateSetting();
    // }
  }


  updateSetting()
  {
    let setting: ScrollableSetting = this.getSetting();

    let usePress = (setting.buttonMode === "press");
    if (this.hamNext && this.hamPrev) {
      this.hamNext.set({ enable: usePress });
      this.hamPrev.set({ enable: usePress });
    }

    let totalWidth = this.$wrapper[0].scrollWidth,
      containerWidth = this.$root.find(".container").width();

    if (setting.buttonMode === "press") {

      // if(this.$root.attr("component") === "index-location-list") console.log(`totalWidth: ${totalWidth}, containerWidth: ${containerWidth}`);

      this.scrollable = totalWidth > containerWidth;
      this.minX = this.scrollable ? containerWidth - totalWidth : 0;

    }
    else {
      this.scrollable = (this.numItems > setting.numCols);

      if (this.scrollable) {
        this.maxIndex = this.numItems - setting.numCols;
        let totalWidth = (setting.itemWidth + setting.gapX) * this.numItems - setting.gapX,
          maskWidth = this.$root.find(".container").width();

        // this.minX = -this.maxIndex * (setting.itemWidth + setting.gapX);
        // this.minX = maskWidth - totalWidth;

        // console.log(`totalWidth: ${totalWidth}, maskWidth: ${maskWidth}`);
      }

      this.minX = this.scrollable ? containerWidth - totalWidth : 0;
    }

    this.$root.attr("scrollable", this.scrollable ? "true" : "false");
    this.hamDrag.get("pan").set({ enable: this.scrollable });

    this.rebound();
  }

  preRebound()
  {


    let setting = this.getSetting();

    if (setting.buttonMode === "press") {
      this.rebound();
    }
    else {
      // let currentX = parseInt(this.$wrapper.css("margin-left")),
      let currentX = this.targetX,
        moveUnit = setting.gapX + setting.itemWidth,
        targetX = moveUnit * -this.currentIndex,
        reboundDistance = setting.reboundDistance === "auto" ? setting.itemWidth * .1 : setting.reboundDistance,
        dx = currentX - targetX,
        targetIndex = this.currentIndex;

      if (dx < -reboundDistance) targetIndex++;
      if (dx > reboundDistance) targetIndex--;

      this.isLocking = false;
      this.toIndex(targetIndex);
    }
  }

  rebound()
  {
    let setting: ScrollableSetting = this.getSetting();

    if (setting.buttonMode === "press") {
      // let targetX = parseInt(this.$wrapper.css("margin-left"));
      let targetX = this.targetX;
      if (targetX < this.minX) gsap.to(this.$wrapper[0], { marginLeft: this.minX });
      if (targetX > this.maxX) gsap.to(this.$wrapper[0], { marginLeft: this.maxX });
    }
    else {
      this.isLocking = false;
      this.toIndex(this.currentIndex);
    }
  }

  toIndex(index: number)
  {
    if (this.isLocking) return;
    if (!this.scrollable) return;
    if (index < this.minIndex) index = this.minIndex;
    if (index > this.maxIndex) index = this.maxIndex;
    

    this.currentIndex = index;
    this.update();
  }

  update()
  {
    let setting: ScrollableSetting = this.getSetting(),
      moveUnit = setting.gapX + setting.itemWidth,
      targetX = moveUnit * -this.currentIndex;

    if (targetX < this.minX) targetX = this.minX;

    this.isLocking = true;
    gsap.killTweensOf(this.$wrapper[0]);
    gsap.to(this.$wrapper[0], {
      duration: .3, marginLeft: targetX, onComplete: () =>
      {
        this.isLocking = false;

        this.emitter.emit("updated", this.currentIndex);
      }
    });
  }

  updateForPress(direction: 1 | -1)
  {
    let startX = parseInt(this.$wrapper.css("margin-left")),
      targetX = direction === 1 ? this.maxX : this.minX,
      dx = Math.abs(targetX - startX),
      duration = dx / SPEED;

    this.targetDirection = direction;

    gsap.killTweensOf(this.$wrapper[0]);
    gsap.to(this.$wrapper[0], { duration: duration, ease: "linear", marginLeft: targetX });
  }

  // updateForPress_old(direction: 1 | -1)
  // {
  //   let maxSpeed = 30,
  //     startX = parseInt(this.$wrapper.css("margin-left"));

  //   let obj = this.pressMoveObj;

  //   obj.targetX = startX;
  //   obj.direction = direction;
  //   obj.skipTween = false;

  //   gsap.killTweensOf(obj);
  //   gsap.to(obj, { duration: .3, speed: (maxSpeed * direction), ease: "linear" });

  //   this.timer.restart();
  // }

  endUpdateForPress()
  {
    let speed = SPEED * .5;

    let currentX = parseInt(this.$wrapper.css(`margin-left`)),
      targetX = this.targetDirection < 0 ? currentX - speed : currentX + speed;
    
    if(targetX < this.minX) targetX = this.minX;
    if(targetX > this.maxX) targetX = this.maxX;

    let dx = Math.abs(currentX - targetX),
    duration = dx / speed;

    // console.log(duration);
    

    // this.timer.pause();
    gsap.killTweensOf(this.$wrapper[0]);
    gsap.to(this.$wrapper[0], {duration: duration, marginLeft: targetX, ease: "power1.out"});
  }

  getSetting()
  {
    if (this.haveMutipleSetting) {
      this.view = ViewportRoot.index === 0 ? "mobile" : "pc";
    }
    else {
      this.view = this.setting.mobile ? "mobile" : "pc";
    }

    return this.setting[this.view];
  }
}